import { type ReactNode } from 'react'

export interface FormGroup {
    className?: string
    horizontal?: boolean
    children: ReactNode
}

export function FormGroup({
    className,
    children,
}: FormGroup) {
    return (
        <div
            className={className}
        >
            {children}
        </div>
    )
}

export default FormGroup
