interface PopupTitle {
  className?: string
  children: React.ReactNode
  type?: string
}

function PopupTitle({ children, className = '', type = '' }: PopupTitle) {
  return (
    <div
      className={`type-headline-medium relative ${className} ${
        type === 'choose_location' ? '' : 'h-[55px]'
      }`}
    >
      {children}
    </div>
  )
}
PopupTitle.displayName = 'PopupTitle'
export { PopupTitle }
export default PopupTitle
